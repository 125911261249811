import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'frame']

  search() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.requestSubmit()
    }, 500)
  }

  requestSubmit() {
    this.frameTarget.innerHTML = ''
    this.frameTarget.setAttribute('busy', true)

    const formValues = new FormData(this.formTarget)
    const searchParams = new URLSearchParams(formValues)

    const url = new URL(this.frameTarget.src)
    url.search = searchParams.toString()
    this.frameTarget.src = url

    this.toggleFormAccess(true)
  }

  toggleFormAccess(disabled = false) {
    this.formTarget.querySelectorAll('input, select').forEach((input) => {
      input.disabled = disabled
      input.classList.toggle('is-disabled', disabled)
    })

    this.formTarget.querySelector('.datetimepicker-dummy').classList.toggle('is-disabled', disabled)
    this.formTarget.querySelector('.choices').classList.toggle('is-disabled', disabled)
  }

  frameLoaded() {
    this.toggleFormAccess(false)
  }
}
